import { SignIn as SignInComponent } from "@clerk/astro/react";
import { dark } from "@clerk/themes";
export function SignIn() {
  return (
    <div className="flex min-h-full flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8">
      <SignInComponent
        appearance={{
          baseTheme: dark,
          elements: {
            formButtonPrimary:
              "bg-makefast-blue border-makefast-blue text-white hover:text-makefast-blue",
          },
        }}
        forceRedirectUrl={"/app"}
      />
    </div>
  );
}
